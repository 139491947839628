module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"강남 사라있네","short_name":"강남 사라있네","description":"강남 사라있네 공식 사이트입니다. 최고의 서비스를 제공하겠습니다. 방문 전 꼭 전화문의 부탁드립니다. 양주13만원 티씨12만원 룸티5만원 입니다.","start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#000000","lang":"ko","orientation":"portrait-primary","icon":"./src/static/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ef66f613539c46231bcd47892755007"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
